import { Button, Paper, dividerClasses } from "@mui/material";
import React from "react";
import styles from "./PdfDownloadButton.module.scss";
import LoremIpsum from "react-lorem-ipsum";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
interface PdfDownloadButtonProps {
  pdfData: Blob;
  fileName?: string;
}

const PdfDownloadButton: React.FC<PdfDownloadButtonProps> = ({
  pdfData,
  fileName,
}) => {
  const handleDownload = () => {
    console.log("pdfData", pdfData);
    // Create a URL for the Blob
    const url = window.URL.createObjectURL(pdfData);

    // Create a temporary anchor element and trigger a download
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName || "modified_pdf.pdf"; // Set the filename here
    document.body.appendChild(a); // Append anchor to body
    a.click(); // Trigger the download
    document.body.removeChild(a); // Remove the anchor from body
    window.URL.revokeObjectURL(url); // Clean up the Blob URL
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h1>Your PDF is Ready!</h1>
      </div>
      <Button
        onClick={handleDownload}
        variant="contained"
        startIcon={<FileDownloadOutlinedIcon />}
      >
        Download PDF
      </Button>
      <div className={styles.explainer}>
        <h2>We've added an outline to your PDF</h2>
        <p>
          Your PDF now has a new outline, created using AI technology to
          organize and structure the content. This outline was initially
          generated by our AI system and then refined based on your input to
          better meet your requirements.
        </p>
        <p>
          To access the outline in your PDF reader, look for the 'Bookmarks' or
          'Contents' section. This is usually located on the left side of the
          screen or in the top menu. The outline is presented as a series of
          clickable items, each corresponding to a different section or chapter
          of your document. By using these links, you can quickly navigate to
          specific parts of the PDF, making it easier to find the information
          you need.
        </p>
      </div>
    </div>
  );
};

export default PdfDownloadButton;

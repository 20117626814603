import React from "react";

const TermsAndConditions: React.FC = () => {
  return (
    <React.Fragment>
      <h1>Terms of Service for PDF Outline Enhancement Service</h1>

      <h2>1. Introduction</h2>
      <p>
        Welcome to our PDF Outline Enhancement Service. By using our service,
        you agree to these Terms of Service. Please read them carefully.
      </p>

      <h2>2. Service Description</h2>
      <p>
        Our service provides the capability to add structured outlines to PDF
        documents to enhance their navigability and usability. This is achieved
        through a combination of AI technology and user input.
      </p>

      <h2>3. Use of Service</h2>
      <p>
        You may use our service only for lawful purposes and in accordance with
        these Terms.
      </p>
      <p>
        You are responsible for the content of the PDFs you submit and have the
        necessary rights or permissions to modify and use them.
      </p>
      <p>
        We do not claim ownership of your content, but you grant us the
        permission to process it for the purpose of providing the service.
      </p>
      <p>
        PDFs enhanced with outlines through our service are for personal,
        non-commercial use only. They may not be used for any commercial
        purposes without our express written consent.
      </p>

      <h2>4. Privacy and Data Protection</h2>
      <p>
        We are committed to protecting your privacy and handling your data in
        compliance with applicable data protection laws.
      </p>
      <p>
        Your PDFs and the data within them will not be shared with third
        parties, except as necessary to provide the service.
      </p>

      <h2>5. Intellectual Property</h2>
      <p>
        The technology used to provide this service, including all underlying
        software, algorithms, and processes, is our intellectual property.
      </p>
      <p>
        By using our service, you do not acquire any intellectual property
        rights in our technology.
      </p>

      <h2>6. Limitation of Liability</h2>
      <p>
        Our service is provided "as is" and "as available" without any
        warranties, either express or implied.
      </p>
      <p>
        We are not liable for any indirect, incidental, special, consequential,
        or punitive damages arising from or related to your use of the service.
      </p>

      <h2>7. Changes to Terms</h2>
      <p>
        We reserve the right to modify these Terms at any time. We will notify
        users of any changes by updating the Terms on our website or through
        other communication.
      </p>
      <p>
        Your continued use of the service after any modification of these Terms
        constitutes acceptance of those changes.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us through
        our website.
      </p>
    </React.Fragment>
  );
};

const PrivacyPolicy: React.FC = () => {
  return (
    <React.Fragment>
      <h1>Privacy Policy for PDF Outline Enhancement Service</h1>

      <h2>Introduction</h2>
      <p>
        This Privacy Policy outlines our practices regarding the collection,
        use, and sharing of information through the PDF Outline Enhancement
        Service. By using our service, you consent to the policies and practices
        described in this document.
      </p>

      <h2>Information Collection and Use</h2>
      <ul>
        <li>
          <strong>PDF Storage:</strong> We use Amazon Web Services (AWS) to
          store the PDFs you upload. These files are retained for up to 30 days
          to provide the service and are then automatically deleted.
        </li>
        <li>
          <strong>OpenAI Analysis:</strong> Excerpts of your PDFs may be
          submitted to OpenAI for analysis purposes. This is to enhance the
          accuracy of the outline generation and improve the overall quality of
          the service.
        </li>
        <li>
          <strong>Cookies:</strong> Our service uses cookies essential for the
          functionality of the service. These cookies are used solely to provide
          our service and are not used for tracking or analytical purposes.
        </li>
        <li>
          <strong>reCAPTCHA:</strong> We use Google's reCAPTCHA service for spam
          protection. This helps us prevent automated abuse and spam on our
          platform.
        </li>
      </ul>

      <h2>Data Security and Sharing</h2>
      <p>
        The PDFs you upload are not shared with any third parties, except as
        necessary to provide our service (AWS for storage and OpenAI for
        analysis).
      </p>
      <p>
        We implement appropriate technical and organizational measures to
        protect your data against unauthorized access, alteration, or
        destruction.
      </p>

      <h2>Your Rights and Choices</h2>
      <p>
        You have the right to access, update, or delete your personal
        information at any time. Please contact us if you wish to exercise these
        rights.
      </p>
      <p>
        You may withdraw your consent to our data practices by discontinuing the
        use of our service.
      </p>

      <h2>Changes to the Privacy Policy</h2>
      <p>
        We reserve the right to update or modify this Privacy Policy at any
        time. Any changes will be posted on our website with an updated revision
        date.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us through our website.
      </p>
    </React.Fragment>
  );
};

export { TermsAndConditions, PrivacyPolicy };

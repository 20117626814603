import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import { Helmet } from "react-helmet-async";
import styles from "./App.module.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoginComponent from "./components/login";
import Tabs from "./components/Tabs";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import AppProgress from "./components/AppProgress";
import Header from "./components/Header";
import Footer from "./components/Footer";

const theme = createTheme({
  palette: {
    primary: {
      main: styles.primary,
    },
    secondary: { main: styles.secondary },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.container}>
        <Helmet>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900"
          />
        </Helmet>
        <Header />

        <AppProgress className={styles.progress} />
        <Footer className={styles.footer} />
      </div>
    </ThemeProvider>
  );
}

export default App;

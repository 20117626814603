import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import { ExtractionRequestResponse } from "../web_api";
import Login from "./AppLogin";
import { PrivacyPolicy, TermsAndConditions } from "./Legal";
import style from "./UploadPdf.module.scss";
import InfoBox from "./InfoBox";

function humanReadableFileSize(size: number) {
  const units = ["B", "KB", "MB", "GB", "TB"];
  let unitIndex = 0;
  while (size > 1024) {
    size /= 1024;
    unitIndex++;
  }
  return `${size.toFixed(2)} ${units[unitIndex]}`;
}

function shortenName(name: string) {
  const MAX_LENGTH = 18;
  if (name.length <= MAX_LENGTH) {
    return name;
  }
  return (
    name.slice(0, Math.floor(MAX_LENGTH / 2) - 3) +
    "..." +
    name.slice(-(Math.floor(MAX_LENGTH / 2) - 3))
  );
}

function UploadPdf(
  file: File,
  callback: (response: ExtractionRequestResponse) => void
) {
  const formData = new FormData();
  formData.append("pdf", file);
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: formData,
  };
  fetch("api/pdf", requestOptions)
    //verify that the response is ok
    .then((response) => {
      if (response.ok) {
        return response;
      }
      throw new Error("Network response was not ok");
    })
    .then((response) => response.json())
    .then((data) => callback(data));
}

interface PdfUploadProps {
  onFinished?: (pdf_id: string) => void;
}

const PdfUpload: React.FC<PdfUploadProps> = (props) => {
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop(acceptedFiles, fileRejections, event) {
      setSelectedFile(acceptedFiles[0]);
    },
  });

  const [currentModal, setCurrentModal] = React.useState<
    "TermsAndConditions" | "PrivacyPolicy" | null
  >(null);

  const onFinished = props.onFinished || (() => {});

  const [uploadState, setUploadState] = React.useState<
    "not_logged_in" | "not_started" | "uploading" | "finished"
  >("not_logged_in");
  const startedUploadAlready =
    uploadState === "uploading" || uploadState === "finished";
  console.log(selectedFile, "acceptedFile");
  const files = acceptedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  const isMobile = useMediaQuery("(max-width: 550px)");
  return (
    <div className={style.uploadContainer}>
      <div
        {...(selectedFile
          ? { className: style.dropzone + " " + style.disabled }
          : getRootProps({ className: `${style.dropzone} ${style.active}` }))}
      >
        <input
          {...getInputProps()}
          accept="application/pdf"
          disabled={Boolean(selectedFile)}
        />
        {selectedFile ? (
          <React.Fragment>
            <div className={style.pdfInfo}>
              <div className={style.pdfName}>
                {shortenName(selectedFile.name)}
              </div>
              {isMobile ? (
                <IconButton onClick={() => setSelectedFile(null)}>
                  <ClearIcon />
                </IconButton>
              ) : (
                <Button
                  onClick={() => setSelectedFile(null)}
                  variant="outlined"
                  startIcon={<ClearIcon />}
                  disabled={startedUploadAlready}
                  className={style.removeButton}
                >
                  Remove
                </Button>
              )}
              <div className={style.pdfSize}>
                {humanReadableFileSize(selectedFile.size)}
              </div>
            </div>
            <div
              className={
                style.pdfAction +
                " " +
                (uploadState === "not_logged_in" ? style.login : style.upload)
              }
            >
              <Login
                onSuccess={() => setUploadState("not_started")}
                className={style.loginButton}
                disabled={uploadState !== "not_logged_in"}
              />
              <Button
                component="span"
                variant="contained"
                startIcon={
                  !startedUploadAlready ? (
                    <CloudUploadIcon />
                  ) : uploadState === "uploading" ? (
                    <CircularProgress size={20} />
                  ) : (
                    <CheckIcon />
                  )
                }
                className={style.uploadButton}
                disabled={!selectedFile || uploadState !== "not_started"}
                onClick={() => {
                  setUploadState("uploading");
                  if (!selectedFile) {
                    console.error("No file selected");
                    return;
                  }
                  UploadPdf(selectedFile, (response) => {
                    console.log(response);
                    setUploadState("finished");
                    onFinished(response.pdf_id);
                  });
                }}
              >
                Upload
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <UploadFileIcon className={style.uploadIcon} />
            <div>Drop PDF document to upload or</div>
            <div className={style.boldText}>Browse files</div>
          </React.Fragment>
        )}
      </div>

      <div className={style.disclaimer}>
        By selecting a file, you agree to our{" "}
        <a
          onClick={() => {
            setCurrentModal("TermsAndConditions");
          }}
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          onClick={() => {
            setCurrentModal("PrivacyPolicy");
          }}
        >
          Privacy Policy
        </a>
        .
      </div>
      <InfoBox className={style.infoBox} />
      <Dialog
        open={currentModal === "TermsAndConditions"}
        onClose={() => setCurrentModal(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Terms of Service</DialogTitle>
        <DialogContent dividers={true}>
          <TermsAndConditions />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCurrentModal(null);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={currentModal === "PrivacyPolicy"}
        onClose={() => setCurrentModal(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Privacy Policy</DialogTitle>
        <DialogContent dividers={true}>
          <PrivacyPolicy />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCurrentModal(null);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PdfUpload;

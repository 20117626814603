import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PdfUpload from "./UploadPdf";
import { ModificationRequest, Outline } from "../web_api";
import WaitForExtraction from "./waitForExtraction";
import OutlineEditor from "./OutlineEditor";
import WaitForModification from "./waitForModification";
import PdfDownloadButton from "./PdfDownloadButton";
import styles from "./AppProgress.module.scss";
import useMediaQuery from "@mui/material/useMediaQuery";
import { StepContent } from "@mui/material";
import InfoBox from "./InfoBox";

function triggerModification(
  pdf_id: string,
  outline: Outline
): Promise<Response> {
  const request: ModificationRequest = {
    pdf_id: pdf_id,
    outline: outline,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  };
  return (
    fetch(`api/pdf/modified/${pdf_id}`, requestOptions)
      //verify that the response is ok
      .then((response) => {
        if (response.ok) {
          return response;
        }
        throw new Error("Network response was not ok");
      })
  );
}

interface AppProgressProps {
  className?: string;
}

const AppProgress: React.FC<AppProgressProps> = ({ className }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [stepFailed, setStepFailed] = React.useState<number | null>(null);
  const [pdfID, setPdfID] = React.useState<string | undefined>(undefined);
  const [outline, setOutline] = React.useState<Outline | undefined>(undefined);
  const [pdfData, setPdfData] = React.useState<Blob | undefined>(undefined);

  const steps: [string, string | null, JSX.Element][] = [
    [
      "Upload PDF",
      null,
      <PdfUpload
        onFinished={(pdf_id) => {
          setPdfID(pdf_id);
          setActiveStep(1);
        }}
      />,
    ],
    [
      "Auto-extract Outline",
      "Takes up to 5 minutes",
      pdfID ? (
        <WaitForExtraction
          pdf_id={pdfID}
          onFinished={(outline) => {
            setOutline(outline);
            setActiveStep(2);
            console.log("outline", outline);
          }}
          onFail={() => {
            setStepFailed(1);
          }}
          status={activeStep === 1 ? "in_progress" : "finished"}
        />
      ) : (
        <div>Upload PDF first</div>
      ),
    ],
    [
      "Review Outline",
      null,
      outline ? (
        <div>
          <OutlineEditor
            outline={outline}
            onUpdate={setOutline}
            onSubmit={() => {
              console.log(outline);
              triggerModification(pdfID!, outline!);
              setActiveStep(3);
            }}
          />
        </div>
      ) : (
        <div>Auto-extract Outline first</div>
      ),
    ],
    [
      "Modify PDF",
      "Takes up to 1 minute",
      activeStep >= 3 ? (
        <WaitForModification
          pdf_id={pdfID!}
          onFinished={(pdf_data) => {
            setPdfData(pdf_data);
            setActiveStep(4);
          }}
          status={activeStep === 3 ? "in_progress" : "finished"}
        />
      ) : (
        <div>Review Outline first</div>
      ),
    ],
    [
      "Download PDF",
      null,
      pdfData ? (
        <PdfDownloadButton pdfData={pdfData} />
      ) : (
        <div>Modify PDF first</div>
      ),
    ],
  ];

  const [_, __, activeContent] = steps[activeStep];
  const isSmallScreen = useMediaQuery("(max-width: 550px)");

  return (
    <div className={className}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel={!isSmallScreen}
        className={styles.stepper}
        orientation={isSmallScreen ? "vertical" : "horizontal"}
      >
        {steps.map(([label, subtext, content], index) => {
          return (
            <Step key={label}>
              <StepLabel
                optional={
                  subtext ? (
                    <Typography variant="caption">{subtext}</Typography>
                  ) : null
                }
                error={stepFailed === index}
              >
                {label}
              </StepLabel>
              {isSmallScreen ? <StepContent>{content}</StepContent> : null}
            </Step>
          );
        })}
      </Stepper>
      {isSmallScreen ? <InfoBox /> : activeContent}
    </div>
  );
};

export default AppProgress;

import React from "react";
import style from "./InfoBox.module.scss";

interface InfoBoxProps {
  className?: string;
}

const InfoBox: React.FC<InfoBoxProps> = ({ className }) => {
  return (
    <div className={className}>
      <h2>How does it work?</h2>
      <p>
        Once you <span className={style.emphasize}>upload your PDF</span>, our
        system immediately gets to work. The first step involves our{" "}
        <span className={style.emphasize}>
          AI technology scanning through your document.
        </span>{" "}
        It analyzes the content to identify key sections and topics, laying the
        groundwork for creating a structured outline. This process is automated,
        ensuring a quick and efficient analysis of your PDF.
      </p>

      <p>
        After the AI has done its part, you'll have the opportunity to{" "}
        <span className={style.emphasize}>
          review and adjust the proposed outline.
        </span>{" "}
        You can make any necessary changes to ensure that the outline accurately
        reflects the structure and content of your document.
      </p>

      <p>
        Once you're satisfied with the outline, our system will{" "}
        <span className={style.emphasize}>integrate it into your PDF</span>.
        This added layer makes your document easier to navigate, allowing for
        quick access to different sections with just a click. You'll then be
        able to{" "}
        <span className={style.emphasize}>download the updated PDF</span>,
        complete with its new outline.
      </p>
    </div>
  );
};

export default InfoBox;

import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

type LoginProps = {
  onSuccess: () => void;
  className?: string;
  disabled?: boolean;
};

const Login: React.FC<LoginProps> = ({ onSuccess, className, disabled }) => {
  const handleLogin = async (value: string | null) => {
    try {
      // Perform the POST request to /login
      const response = await fetch("api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          recaptcha_response: value,
        }),
      });

      // Check if the request was successful
      if (response.ok) {
        // Call the onSuccess callback
        onSuccess();
      } else {
        // Handle the error case if needed
      }
    } catch (error) {
      // Handle any network or other errors
    }
  };

  const isMobile = useMediaQuery("(max-width: 550px)");
  return (
    <ReCAPTCHA
      sitekey="6LcpNEQpAAAAAHrCHdZy5olrHga5deLSRpYvsntc"
      onChange={handleLogin}
      size={isMobile ? "compact" : "normal"}
      className={className}
    />
  );
};

export default Login;

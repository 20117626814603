import React from "react";
import styles from "./Header.module.scss";

const Header = () => {
  return (
    <header className={styles.header}>
      <h1>Autogenerate PDF outlines</h1>
      <div className={styles.ad}>
        Enhance your PDF books and documents with AI-generated outlines.
      </div>
      <div className={styles.free}>
        Free for private use. No Ads. No Registration.
      </div>
    </header>
  );
};

export default Header;

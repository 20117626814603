import React, { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./WaitScreen.module.scss";
import LinearProgress from "@mui/material/LinearProgress";
import { blob } from "stream/consumers";

type WaitScreenProps = {
  status: "in_progress" | "finished";
  expectedTime: number;
  startExponentialDecay?: number;
  waitStatements?: string[];
  waitStatementUpdateInterval?: number;
};

function pingUntilFinished(
  path: string,
  resolve_json: boolean
): [Promise<Response | any>, () => void] {
  const cancel_wrapper = {
    cancel: false,
  };
  const headerOptions = {
    headers: {
      Accept: resolve_json ? "application/json" : "application/pdf",
    },
  };
  function createPromise(): Promise<Response> {
    return new Promise((resolve, reject) => {
      const interval = setTimeout(() => {
        fetch(path, headerOptions)
          .catch((error) => {
            console.error(error);
            reject(error);
          })
          .then((response): void => {
            if (!response?.ok) {
              console.log("Response not OK", response);
              reject(response);
            } else if (
              response.body &&
              response.headers.get("Ready") !== "False"
            ) {
              let promise: Promise<any | Blob>;
              if (resolve_json) {
                promise = response.json();
              } else {
                promise = response.blob();
              }
              promise.then((json) => {
                if (json !== null) {
                  resolve(json);
                } else if (!cancel_wrapper.cancel) {
                  createPromise().then(resolve).catch(reject);
                }
              });
            } else if (!cancel_wrapper.cancel) {
              createPromise().then(resolve).catch(reject);
            }
          });
      }, 1000); // Ping every 1 seconds
    });
  }
  function cancel() {
    cancel_wrapper.cancel = true;
  }
  return [createPromise(), cancel];
}

const WaitScreen: React.FC<WaitScreenProps> = ({
  status,
  expectedTime: expected_time,
  startExponentialDecay = 0.5,
  waitStatements: wait_statements,
  waitStatementUpdateInterval = 4000,
}) => {
  const [currentText, setCurrentText] = React.useState<number>(0);
  const [currentProgress, setCurrentProgress] = React.useState<number>(0);
  const used_wait_statements = wait_statements || [
    "Wait...",
    "Wait a little more...",
    "Still waiting...",
    "Almost there...",
    "Just a little more...",
    "Still waiting...",
    "Almost there...",
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText(
        (currentText) => (currentText + 1) % used_wait_statements.length
      );
    }, waitStatementUpdateInterval);
    return () => clearInterval(interval);
  }, [used_wait_statements, waitStatementUpdateInterval]);
  useEffect(() => {
    // The idea is that we use a random time between 0 and 1 second to do the
    // next update. On each update, we increase the progress by
    // [progress_expected_in_that_time * random_multiplicator]
    // The random multiplicator is a random number between 0.5 and 1.5.
    // To make the appearance of steady progress, we will time it so that we
    // arrive at 80% progress at 80% of `expected_time`.
    // For the rest of the time, we use geometric progressions to make the
    // progress appear steady.
    // The added progress after 80% is calculated as 0.2 * (1 - exp(-t / expected_time / 0.2)).
    // The derivative of that is (1 / expected_time) * exp(-t / expected_time).
    // At zero, that is (1 / expected_time, which is what we want)
    const start_time = Date.now();
    const getExpectedProgress = (time: number) => {
      if (time < startExponentialDecay * expected_time) {
        return time / expected_time;
      } else {
        const restTime = time - startExponentialDecay * expected_time;
        return (
          startExponentialDecay +
          (1 - startExponentialDecay) *
            (1 -
              Math.exp(-restTime / expected_time / (1 - startExponentialDecay)))
        );
      }
    };
    interface IntervalIdWrapper {
      intervalId: NodeJS.Timeout | null;
    }
    const intervalIdWrapper: IntervalIdWrapper = {
      intervalId: null,
    };
    const updateProgress = (lastExpectedProgress: number) => {
      const currentTime = Date.now();
      const timeSinceStart = currentTime - start_time;
      const expectedProgress = getExpectedProgress(timeSinceStart);
      const progressDelta = expectedProgress - lastExpectedProgress;
      const randomMultiplicator = progressDelta > 0 ? 0.5 + Math.random() : 0;
      const newProgress =
        lastExpectedProgress + progressDelta * randomMultiplicator;
      if (status === "in_progress") {
        setCurrentProgress(newProgress);
        if (newProgress < 1) {
          intervalIdWrapper.intervalId = setTimeout(
            () => updateProgress(newProgress),
            Math.random() * 1000
          );
        }
      }
    };
    if (status === "in_progress") {
      intervalIdWrapper.intervalId = setTimeout(
        () => updateProgress(0),
        Math.random() * 1000
      );
      return () => clearInterval(intervalIdWrapper.intervalId!);
    }
  }, [status, expected_time]);
  return (
    <div className={styles.wrapper}>
      <LinearProgress
        variant="determinate"
        value={status === "in_progress" ? currentProgress * 100 : 100}
      />
      <div className={styles.circleTextWrapper}>
        <CircularProgress className={styles.circle} />
        <div className={styles.textWrapper}>
          {used_wait_statements.map((statement, index) => (
            <div
              key={index}
              className={`${styles.text} ${
                index === currentText ? styles.visible : styles.hidden
              }`}
            >
              {statement}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { pingUntilFinished, WaitScreen };

import React, { useEffect, useState } from "react";
import axios from "axios"; // Assuming you are using axios for HTTP requests
import { Outline, ExtractedInformation } from "../web_api";
import { json } from "stream/consumers";
import { WaitScreen, pingUntilFinished } from "./WaitScreen";
import { Alert } from "@mui/material";
import style from "./WaitForExtraction.module.scss";

interface WaitForModificationProps {
  onFinished?: (pdf_data: Blob) => void;
  onFail?: () => void;
  pdf_id: string;
  status: "in_progress" | "finished";
}

const modifyingPdfMessages = [
  "Rearranging pixels for a perfect outline...",
  "Drawing invisible lines on your PDF...",
  "Conducting an orchestra of bytes and bits...",
  "Sprinkling some digital fairy dust on the outline...",
  "Tailoring the outline to fit like a glove...",
  "Turning the PDF chaos into an organized outline...",
  "Beautifying your PDF with a shiny new outline...",
  "Choreographing a ballet of document outlines...",
  "Sewing the seams of the PDF outline...",
  "Gently embedding secrets into the PDF fabric...",
  "Painting the outline with strokes of data...",
  "Sculpting the perfect structure into your PDF...",
  "Weaving the threads of information into an outline...",
];

const WaitForModification: React.FC<WaitForModificationProps> = ({
  onFinished,
  onFail,
  pdf_id,
  status,
}) => {
  const [failed, setFailed] = useState(false);
  useEffect(() => {
    const [promise, cancel] = pingUntilFinished(
      `api/pdf/modified/${pdf_id}`,
      false
    );
    promise
      .then((response) => {
        onFinished?.(response);
      })
      .catch((error) => {
        console.error(error);
        setFailed(true);
        onFail && onFail();
      });

    return () => cancel(); // Clean up on unmount
  }, []);

  return failed ? (
    <div className={style.errorWrapper}>
      <Alert severity="error" className={style.error}>
        There has been an error modifying your PDF. We're working on a fix.
        <br />
        Sorry for the inconvenience.
      </Alert>
    </div>
  ) : (
    <WaitScreen
      status={status}
      expectedTime={30 * 1000}
      waitStatements={modifyingPdfMessages}
    />
  );
};

export default WaitForModification;

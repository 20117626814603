import React, { useState } from "react";
import { Chapter, Outline } from "../web_api";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./OutlineEditor.module.scss";
import {
  Tree,
  TreeCheckboxSelectionKeys,
  TreeMultipleSelectionKeys,
} from "primereact/tree";
import { TreeNode } from "primereact/treenode";
import "primereact/resources/themes/mdc-light-indigo/theme.css";
import { stringify } from "querystring";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

interface OutlineEditorProps {
  outline: Outline;
  onUpdate: (updatedOutline: Outline) => void;
  onSubmit?: () => void;
}

interface ChapterEditorProps {
  chapter?: Chapter;
  onUpdate: (updatedChapter: Chapter) => void;
}

const ChapterEditor: React.FC<ChapterEditorProps> = ({ chapter, onUpdate }) => {
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    chapter && onUpdate({ ...chapter, title: event.target.value });
  };

  const handleNumberingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    chapter && onUpdate({ ...chapter, numbering: event.target.value });
  };

  return (
    <div className={styles.titleEditorWrapper}>
      <TextField
        className={styles.numberingEditor}
        variant="standard"
        label="Numbering"
        value={chapter?.numbering || ""}
        onChange={handleNumberingChange}
        disabled={chapter === undefined}
        size="small"
      />
      <TextField
        variant="standard"
        label="Title"
        value={chapter?.title || ""}
        onChange={handleTitleChange}
        disabled={chapter === undefined}
        size="small"
      />
      <div className={styles.pageNumber}>
        {chapter === undefined ? (
          "Select Chapter to Edit"
        ) : (
          <React.Fragment>
            Page Number:{" "}
            <span className={styles.pageNumberSpan}>
              {chapter?.page_number}
            </span>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const OutlineEditor: React.FC<OutlineEditorProps> = ({
  outline,
  onUpdate,
  onSubmit,
}) => {
  // step 1: build TreeNodes from the chapters
  let chapterId = 0;
  const chapterToIdMap = new Map<Chapter, number>();
  const idToChapterMap = new Map<number, Chapter>();
  const assignIds = (chapter: Chapter) => {
    chapterId++;
    chapterToIdMap.set(chapter, chapterId);
    idToChapterMap.set(chapterId, chapter);
    if (chapter.subchapters) {
      chapter.subchapters.forEach(assignIds);
    }
  };
  outline.chapters?.forEach(assignIds);
  const chapterToTreeNode = (chapter: Chapter): TreeNode => {
    return {
      key: chapterToIdMap.get(chapter)!,
      label: `${chapter.numbering ? chapter.numbering + ". " : ""}${
        chapter.title
      }`,
      children: chapter.subchapters?.map(chapterToTreeNode),
    };
  };
  const treeNodes = outline.chapters?.map(chapterToTreeNode);
  const fromTreeNodeToChapter = (treeNode: TreeNode): Chapter => {
    return {
      ...idToChapterMap.get(treeNode.key as unknown as number)!,
      subchapters: treeNode.children?.map(fromTreeNodeToChapter),
    };
  };
  const fromTreeNodeToOutline = (treeNodes: TreeNode[]): Outline => {
    return {
      ...outline,
      chapters: treeNodes.map(fromTreeNodeToChapter),
    };
  };

  const [selectedKey, setSelectedKey] = useState<
    | string
    | TreeMultipleSelectionKeys
    | TreeCheckboxSelectionKeys
    | null
    | undefined
  >();
  return (
    <div className={styles.container}>
      <Tree
        value={treeNodes}
        selectionMode="single"
        selectionKeys={selectedKey}
        onSelectionChange={(e) => {
          console.log(e.value);
          setSelectedKey(e.value);
        }}
        className={styles.tree}
        dragdropScope="outline-editor"
        onDragDrop={(e) => {
          onUpdate(fromTreeNodeToOutline(e.value));
        }}
      />
      <div className={styles.buttonWrapper}>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          startIcon={<AutoFixHighIcon />}
        >
          Add Outline to PDF
        </Button>
      </div>
      <div className={styles.editor}>
        <ChapterEditor
          chapter={idToChapterMap.get(selectedKey as unknown as number)!}
          onUpdate={(updatedChapter) => {
            const newOutline = { ...outline };
            const updateChapter = (chapter: Chapter): Chapter => {
              if (
                chapterToIdMap.get(chapter) ===
                (selectedKey as unknown as number)
              ) {
                return updatedChapter;
              } else {
                return {
                  ...chapter,
                  subchapters: chapter.subchapters?.map(updateChapter),
                };
              }
            };
            newOutline.chapters = newOutline.chapters?.map(updateChapter);
            onUpdate(newOutline);
          }}
        />
      </div>
    </div>
  );
};

export default OutlineEditor;

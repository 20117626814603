import React from "react";
import style from "./Footer.module.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { PrivacyPolicy, TermsAndConditions } from "./Legal";

interface FooterProps {
  className?: string;
}

const Footer: React.FC<FooterProps> = ({ className }) => {
  const [openedDialog, setOpenedDialog] = React.useState<
    "none" | "privacy" | "terms"
  >("none");
  return (
    <footer className={`${style.footer} ${className}`}>
      <div className={style.contact}>
        <div className={style.title}>Contact</div>
        Email:
        <a href="mailto:pdf-outline@posteo.de">pdf-outline@posteo.de</a>
      </div>
      <div className={style.legal}>
        <div className={style.title}>Legal</div>
        <a
          onClick={() => {
            setOpenedDialog("terms");
          }}
        >
          Terms and Conditions
        </a>
        <br />
        <a
          onClick={() => {
            setOpenedDialog("privacy");
          }}
        >
          Privacy Policy
        </a>
      </div>
      <Dialog
        open={openedDialog === "privacy"}
        onClose={() => {
          setOpenedDialog("none");
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Privacy Policy</DialogTitle>
        <DialogContent dividers={true}>
          <PrivacyPolicy />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("none");
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openedDialog === "terms"}
        onClose={() => {
          setOpenedDialog("none");
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Terms and Conditions</DialogTitle>
        <DialogContent dividers={true}>
          <TermsAndConditions />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("none");
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import axios from "axios"; // Assuming you are using axios for HTTP requests
import { Outline, ExtractedInformation } from "../web_api";
import { json } from "stream/consumers";
import { WaitScreen, pingUntilFinished } from "./WaitScreen";
import style from "./WaitForExtraction.module.scss";
import { Alert } from "@mui/material";

interface WaitForExtractionProps {
  onFinished?: (outline: Outline) => void;
  onFail?: () => void;
  pdf_id: string;
  status: "in_progress" | "finished";
}

const loadingMessages = [
  "Scanning your PDF with x-ray vision...",
  "Do not close this tab, we are working hard on your PDF...",
  "Teaching the AI to read... Hang tight!",
  "Consulting the digital oracles about your PDF...",
  "Calibrating AI goggles for deep PDF analysis...",
  "Gently persuading the bytes to reveal their secrets...",
  "Convincing the PDF to spill the beans...",
  "Bribing the pixels for a sneak peek into your PDF...",
  "Performing magic tricks on the PDF...",
  "Taking a quick look inside your PDF... No peeking!",
  "Tuning our AI antennas to the frequency of your PDF...",
  "Warming up our AI neurons for some heavy lifting...",
  "Playing hide and seek with the contents of your PDF...",
  "Doing a little PDF dance, be back in a jiffy...",
  "Engaging in some top-secret PDF whispering...",
  "Whispering sweet nothings to the PDF for faster results...",
];

const WaitForExtraction: React.FC<WaitForExtractionProps> = ({
  onFinished,
  onFail,
  pdf_id,
  status,
}) => {
  const [failed, setFailed] = useState(false);
  useEffect(() => {
    const [promise, cancel] = pingUntilFinished(
      `api/pdf/outline/${pdf_id}`,
      true
    );
    promise
      .then((response) => {
        onFinished && onFinished(response.possible_outlines[0]);
      })
      .catch((error) => {
        console.error(error);
        setFailed(true);
        onFail && onFail();
      });

    return () => cancel(); // Clean up on unmount
  }, []);

  return failed ? (
    <div className={style.errorWrapper}>
      <Alert severity="error" className={style.error}>
        There has been an error generating your outline. We're working on a fix.
        <br />
        Sorry for the inconvenience.
      </Alert>
    </div>
  ) : (
    <WaitScreen
      status={status}
      expectedTime={5 * 60 * 1000}
      waitStatements={loadingMessages}
    />
  );
};

export default WaitForExtraction;
